<template>
  <div>
    <b-modal
      v-model="showModal"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      ok-title="รับทราบ"
      ok-variant="warning"
      @hidden="onModalHidden"
    >
      <template #modal-title>
        ประกาศสำคัญ!<br />
        ทันตแพทย์ทุกท่านต้องเข้ารับการอบรม</template
      >
      <p>
        ภายในเดือนตุลาคม 2567 ทันตแพทย์ทุกท่าน รวมถึงทันตแพทย์ Part Time
        ที่ให้บริการในโครงการ “บัตรประชาชนใบเดียว” <br />
        <br />
        จะต้องผ่านการอบรมจากทันตแพทยสภา และได้รับ e-Certificate <br />
        <br />
        โดยทันตแพทย์ Part Time สามารถเข้ารับการอบรมแบบย่อ (1 ชั่วโมง) <br />
        <br />

        <strong>
          เพื่อให้การให้บริการเป็นไปตามมาตรฐาน และไม่เกิดปัญหาการเบิกจ่ายเงิน
        </strong>
      </p>
      <b-img
        fluid
        src="https://dentcloud-public.s3.ap-southeast-1.amazonaws.com/S__36413446.jpg"
        alt=""
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NotificationModal",
  props: {},
  data() {
    return {
      showModal: false,
      headerBgVariant: "danger",
      headerTextVariant: "light",
      bodyBgVariant: null,
      bodyTextVariant: null,
      footerBgVariant: null,
      footerTextVariant: null,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
    }),
    uid() {
      return this.userInfo.uid;
    },
  },
  methods: {
    checkAndShowModal() {
      const lastShownDate = localStorage.getItem(`lastModalShown_${this.uid}`);
      const today = new Date().toDateString();

      if (lastShownDate !== today) {
        this.showModal = true;
      }
    },
    onModalHidden() {
      const today = new Date().toDateString();
      localStorage.setItem(`lastModalShown_${this.uid}`, today);
    },
  },
};
</script>