<template>
  <div class="ads-container mb-3" ref="container">
    <b-card v-if="adList.length !== 0" no-body class="bg-transparent dc-info-panel">
      <b-carousel
        id="carousel-1"
        v-model="index"
        :interval="15000"
        controls
        indicators
        background="#ffffff00"
        class="transparent-carousel"
      >
        <b-carousel-slide
          v-for="ad in adList"
          :key="ad.val"
          @click.native="openInNewTab(ad.link)"
        >
          <template #img>
            <div class="carousel-image-container">
              <img :src="ad.url" :alt="ad.val" class="carousel-image" />
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdvertiseCard",
  data() {
    return {
      index: 0,
    };
  },
  computed: {
    ...mapGetters({
      getAds: "moduleMaster/getAds",
    }),
    adList() {
      let formattedList = this.formatAdList(this.getAds) || [];
      this.shuffleArray(formattedList);
      return formattedList;
    },
  },
  methods: {
    openInNewTab(url) {
      window.open(url, "_blank", "noreferrer");
    },
    formatAdList(list) {
      if (list?.length < 1) return [];
      return list
        .filter(i => i.desc)
        .map(i => ({ ...i, url: i.desc, link: i.label }));
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    updateContainerSize() {
      if (!this.$refs.container) return;

      const containerRect = this.$refs.container.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const availableHeight = windowHeight - containerRect.top - 20; // 20px buffer
      const containerWidth = this.$refs.container.clientWidth;

      const maxHeight = Math.min(availableHeight, (containerWidth * 9) / 16);
      const finalHeight = Math.max(240, maxHeight); // Ensure minimum height of 240px

      this.$refs.container.style.setProperty(
        "--container-width",
        `${containerWidth}px`
      );
      this.$refs.container.style.setProperty(
        "--container-height",
        `${finalHeight}px`
      );
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateContainerSize);
    this.$nextTick(this.updateContainerSize);
  },
  updated() {
    this.$nextTick(this.updateContainerSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateContainerSize);
  },
};
</script>

<style scoped>
.ads-container {
  width: 100%;
  max-width: 100vw;
  height: var(--container-height, 240px);
  min-height: 240px;
  overflow: hidden;
}

.dc-info-panel {
  height: 100%;
}

.transparent-carousel {
  height: 100%;
}

.carousel-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.carousel-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.b-carousel-slide {
  cursor: pointer;
}

.transparent-carousel ::v-deep .carousel-inner {
  background-color: transparent !important;
  height: 100%;
}

.transparent-carousel ::v-deep .carousel-item {
  height: 100%;
}

.transparent-carousel ::v-deep .carousel-caption {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}
</style>